* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: Arial, sans-serif; */
}


/* :root {
    --theme-color: #ff4500;
    --background-color: #fff;
} */