.sendotp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #f9f9f9;
}

.sendotp-title {
    color: #ff4500;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.sendotp-label {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
}

.otp-input-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
}

.otp-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.timer-resend-container {
    margin-bottom: 20px;
}

.timer {
    font-size: 16px;
    color: #333;
}

.resend-button,
.submit-button {
    background-color: #ff4500;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.resend-button:hover,
.submit-button:hover {
    background-color: #e03e00;
}

.forgot_box.forgot_box_w {
    width: 100%;
}
.LoginGroup.text-center,.text-center{
    text-align: center;
}