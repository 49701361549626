

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
 
* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-size: 14px;
    scrollbar-width: thin;
    font-family: "Roboto", sans-serif;

}
a{
    text-decoration: none;
    color: #000;
}
p{
    margin: 0;
}
ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
button{
    border: none;
}
.MuiModal-backdrop.css-4nmryk-MuiBackdrop-root-MuiModal-backdrop{
    display: none;
}
body{
    overflow: auto !important;
}
input {
    pointer-events: auto !important;
}

.MuiDateCalendar-root .MuiPickersCalendarHeader-root{
    background-color: #b9ddfd;
    margin-top: 0;
}
.Pointer{
    cursor: pointer;
}
